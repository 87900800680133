<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <div v-if="Object.keys(assignment).length === 0" class="d-flex all-height align-center justify-center">
        <div>
          <div class="mb-2 title">No assignment found!</div>
          <v-btn color="primary" @click="$nova.gotoLink({path: '/ia/engagement'})"><v-icon left>mdi-arrow-left</v-icon> Go back to Engagement List</v-btn>
        </div>
      </div>
      <div v-else>
        <v-card class="shadow2-a mb-4">
            <v-card-text class="">
              <div>
                <div class="subtitle-1 font-weight-bold mb-1">Progress</div>
                <div class="pb-1">
                  <span class="font-weight-bold mr-2">Assignment Period:</span>
                  <span>{{$nova.formatDate(assignment.from)}} - {{$nova.formatDate(assignment.to)}}</span>
                </div>
                <div class="pb-1">
                  <span class="font-weight-bold mr-2">Areas Covered:</span>
                  <v-chip small label outlined class="mr-1" v-for="(v,k) in scopelist" :key="k">{{v.name}}</v-chip>
                </div>
                <div class="mt-4">
                  <v-row>
                    <v-col class="pr-0">
                      <div class="d-flex align-center">
                        <v-divider></v-divider>
                        <v-avatar size="26" :color="(status.scope && status.pbc && status.file && status.inputparameter)?'success':'info'"><strong class="white--text">1</strong></v-avatar>
                        <v-divider></v-divider>
                      </div>
                      <div class="text-center mt-4">
                        <div class="pb-0 subtitle-1" >
                          <strong>Setup</strong>
                        </div>
                        <div class="d-flex">
                          <v-spacer></v-spacer>
                          <div class="pb-4 pt-0 text-center">
                            <div class="mt-1" >
                              <v-btn elevation="0" text @click="$nova.gotoLink({path: '/ia/assignment/setup/'+id, hash: '#scope'})">
                                <v-icon v-if="status.scope" small color="success" class="mr-2">mdi-check-circle-outline</v-icon> 
                                <v-icon v-else small color="info" class="mr-2">mdi-timer-sand</v-icon> 
                                Scope Mapping
                              </v-btn>
                            </div>
                            <div class="mt-1" >
                              <v-btn elevation="0" text @click="$nova.gotoLink({path: '/ia/assignment/setup/'+id, hash: '#pbc'})">
                                <v-icon v-if="status.pbc" small color="success" class="mr-2">mdi-check-circle-outline</v-icon> 
                                <v-icon v-else small color="info" class="mr-2">mdi-timer-sand</v-icon> 
                                PBC
                              </v-btn>
                            </div>
                          </div>
                          <v-spacer></v-spacer>
                        </div>
                      </div>
                    </v-col>
                    <v-col class="px-0">
                      <div class="d-flex align-center">
                        <v-divider></v-divider>
                        <v-avatar size="26" :color="(status.testing && status.followup)?'success':'info'"><strong class="white--text">2</strong></v-avatar>
                        <v-divider></v-divider>
                      </div>
                      <div class="text-center mt-4">
                        <div class="pb-0 subtitle-1" >
                          <strong>Testing</strong>
                        </div>
                        <div class="d-flex">
                          <v-spacer></v-spacer>
                          <div class="pb-4 pt-0 text-center">
                            <div class="mt-1" >
                              <v-btn elevation="0" text @click="$nova.gotoLink({path: '/ia/assignment/setup/'+id, hash: '#testing'})">
                                <v-icon v-if="status.testing" small color="success" class="mr-2">mdi-check-circle-outline</v-icon> 
                                <v-icon v-else small color="info" class="mr-2">mdi-timer-sand</v-icon> 
                                Testing Routine
                              </v-btn>
                            </div>
                            <div class="mt-1" >
                              <v-btn elevation="0" text @click="$nova.gotoLink({path: '/ia/assignment/setup/'+id, hash: '#followup'})">
                                <v-icon v-if="status.followup" small color="success" class="mr-2">mdi-check-circle-outline</v-icon> 
                                <v-icon v-else small color="info" class="mr-2">mdi-timer-sand</v-icon> 
                                Follow-up Audit
                              </v-btn>
                            </div>
                          </div>
                          <v-spacer></v-spacer>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card-text>
        </v-card>

      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'assignment_progress',
  data: function(){
    return {
      id: "",
      loading: true,
      assignment: {},
      scopelist: [],
      status: {scope: false, pbc: false, file: false, inputparameter: false, testing: false, followup: false, draftreport: false, managementresponse: false, finalreport: false},
    }
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  beforeDestroy(){
    this.$store.commit("setCustomMenu", []);
  },
  methods: {
    refreshData() {
      this.id = this.$route.params.id || "";
      this.loading = true;
      this.assignment = {};
      this.scopelist = [];
      this.status = {scope: false, pbc: false, file: false, inputparameter: false, testing: false, followup: false, draftreport: false, managementresponse: false, finalreport: false};
      this.getData();
      
    },
    getData(){
      this.errors = {};
      this.loading = true;
      this.assignment = {};
      this.axios.post("/v2/ia/assignment/get/"+this.id).then(dt => {
        
        if(dt.data.status === "success" && dt.data.data.length > 0){
          let data = dt.data.data[0] || {};
          this.assignment = (data.assignment || {})[0] || {};
          if(this.assignment.setup_fileconfig) this.status.file = true;
          if(this.assignment.setup_inputparameterconfig) this.status.inputparameter = true;
          if(this.assignment.setup_scope) this.status.scope = true;
          let engagement_scope = ((data.engagement_detail || {}).engagement_setup || {}).scope;

          for (const i of data.scope_detail) {
              if(engagement_scope.indexOf(i._id) > -1) this.scopelist.push(i);
          }
          let custommenu = [
            { divider: true},
            {title: 'Assignment Progress', icon: 'mdi mdi-view-dashboard-outline', href: '/ia/assignment/view/'+this.id},
            { header: true, title: 'Assignment Setup' },
            {title: 'Scope Mapping', icon: this.status.scope?'mdi mdi-check-circle-outline':'mdi mdi-timer-sand', href: '/ia/assignment/setup/'+this.id, hash: '#scope'},
            {title: 'PBC', icon: this.status.pbc?'mdi mdi-check-circle-outline':'mdi mdi-timer-sand', href: '/ia/assignment/setup/'+this.id, hash: '#pbc'},
            { header: true, title: 'Testing' },
            {title: 'Testing Routine', icon: this.status.testing?'mdi mdi-check-circle-outline':'mdi mdi-timer-sand', href: '/ia/assignment/setup/'+this.id, hash: '#testing'},
            {title: 'Follow-up Audit', icon: this.status.followup?'mdi mdi-check-circle-outline':'mdi mdi-timer-sand', href: '/ia/assignment/setup/'+this.id, hash: '#followup'},
          ];
          this.$store.commit("setCustomMenu", custommenu);
        }else {
          this.$store.commit("setCustomMenu", []);
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).then(dt => {
        console.log("dummy", dt);
      }).then(dt => {
        console.log("dummy", dt);
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  computed: {
  },
  watch: {
    "$store.state.client": function(){
      this.refreshData();
    }
  }
}
</script>
